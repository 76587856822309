
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
// import AddinvoiceDrawer from '@/layout/header/partials/course/AddinvoiceDrawer.vue';
// import EditinvoiceInfoDrawer from '@/layout/header/partials/course/EditinvoiceInfoDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';
import moment from "moment";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

export default defineComponent({
  name: 'invoice-list',
  components: {
    Datatable,
    Field,
    // AddinvoiceDrawer,
    // EditinvoiceInfoDrawer,
  },


  data() {
    return {
      load: false,
      componentKey: 0,
      moment: "" as any,

      tableHeader: [
        {
          name: 'Invoice Number',
          key: 'invoice_no',
          width: '100px',
          // sortable: true,
        },
        {
          name: 'Customer Name',
          key: 'customer_name',
          // sortable: true,
        },
        // {
        //   name: 'Salesman Name',
        //   key: 'salesman_name',
        //   // sortable: true,
        // },
        {
          name: 'Date',
          key: 'date',
          // sortable: true,
        },
        {
          name: 'SO Number',
          key: 'so_number',
          // sortable: true,
        },
        {
          name: 'Do Number',
          key: 'do_number',
          // sortable: true,
        },
        {
          name: 'Due Date',
          key: 'due_date',
          // sortable: true,
        },

        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
        },
      ],
      
      customerList: [] as any,
      salesmanList: [] as any,
      categoryList: [] as any,
      ready: false,
      start_date: '',
      end_date: '',
      customer_id: '',
      product_category_id:'',
      so_number:'',
      invoice_number: '' as any,
      lists: [],
      search: '',
      tableData: [],
      data: {},
      user_id:'' as any,
      dataTableInstance: $('#salesList'),
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      this.moment = moment;
      await this.getCustomerList();
      // await this.getinvoiceData();
        this.emitter.on('invoice-added', async () => {
        // await this.getinvoiceData();
      });
      
    } else {
      this.$router.push('/404');
    }
  },
  
  methods: {
    async getinvoiceData() {
      this.ready= false;
      this.load = true;
      let start_date= this.start_date;
      let end_date= this.end_date;
      let customer_id= this.customer_id;
      let so_number= this.so_number;
      let invoice_number= this.invoice_number;
      await ApiService.get('finmanInvoice/invoice/list?type=1&startdate='+ `${start_date}` + '&enddate='+ `${end_date}` + '&customer='+ `${customer_id}` + '&so='+ `${so_number}` + '&invoice_number='+ `${invoice_number}` )
        .then((response) => {
          this.tableData = response.data.data;
          this.load=false;
          this.ready=true;
          this.componentKey += 1;

          // Destroy the existing DataTable instance before reinitializing
          if (this.dataTableInstance) {
            this.dataTableInstance.destroy();
            this.dataTableInstance = null;
          }

          // Initialize DataTable on the updated table
          this.$nextTick(() => {
            this.dataTableInstance = $('#salesList').DataTable({
              paging: false
            });
          });
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    // editinvoice(data) {
    //   this.emitter.emit('invoice-edit-type', data);
    // },

    async getCustomerList() {
      await ApiService.get('finmanInvoice/customer_list')
        .then((response) => {
          this.customerList = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    addInvoice(){
      this.$router.push('add-invoice');
    },

    viewInvoice(invoice_id) {
      let invoiceData=[] as any;
      ApiService.get("finmanInvoice/invoice/getInvoiceDetails/"+ `${invoice_id}`)
        .then((response) => {
          invoiceData= response.data.data;
          this.emitter.emit('sales-view-type', invoiceData);
        }).catch(({ response }) => {
              console.log(response);
        });
    },
    viewInvoiceDetail(param_invoice_no) {
      this.$router.push(
        {
          path: 'view-invoice',
          query: 
          {
            // id: invoice_id,
            invoice_no: param_invoice_no,
            type: 1,
          }
        });
    },

    editInvoice(invoice_id) {
      this.emitter.emit('sales-edit-type', invoice_id);
      this.$router.push(
        {
          path: 'edit-invoice',
          query: 
          {
            id: invoice_id,
          }
        });
    },
    

    Deleteinvoice(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/invoice/deleteSale/' + `${id}`)
            .then((response) => {
              this.emitter.emit('invoice-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
              location.reload();
            })
            .catch(({ response }) => {
              console.log(response);
              location.reload();
            });
        }
      });
    },
  },
  // beforeDestroy() {
  //   if ($.fn.DataTable.isDataTable($(this.$refs.salesInvoices))) {
  //     $(this.$refs.salesInvoices).DataTable().destroy(true);
  //   }
  // }

  mounted() {
    // Initialize DataTables when the component is mounted
    this.dataTableInstance = $('#salesList').DataTable();
  },

  
});
